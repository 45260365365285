<!-- eslint-disable max-len -->
<i18n>
  {
    "de": {
      "error_auth_disabled": "Ihr Account konnte nicht aktiviert werden, da ein Problem mit der Konfiguration aufgetreten ist. Bitte wenden Sie sich an Ihren Administrator.",
      "intro": "Bitte drücken Sie den Button »{submitButtonText}« um Ihr Benutzerkonto zu aktivieren:",
      "submit_button": "Registrierung abschließen",
      "success_already_verified": "Ihr Account ist bereits bestätigt und aktiviert. Bitte gehen Sie in die my-picturemaxx Anwendung zurück und setzen Sie den Login dort fort.",
      "success_message": "Vielen Dank für Ihre Bestätigung. Ihr Account ist nun aktiviert. Bitte gehen Sie in die my-picturemaxx Anwendung zurück und setzen Sie den Login dort fort.",
      "title": "Benutzerkonto wurde aktiviert",
      "title_init": "Bestätigung erforderlich",
      "title_loading": "Benutzerkonto wird aktiviert..."
    },
    "en": {
      "error_auth_disabled": "Your account could not be activated due to a configuration problem. Please contact your administrator.",
      "intro": "Please push the button ‘{submitButtonText}’ below to activate your account:",
      "submit_button": "complete registration",
      "success_already_verified": "Your account is already confirmed and activated. Please go back to the my-picturemaxx application and continue the login there.",
      "success_message": "Thank you very much for your confirmation. Your account is now activated. Please go back to the my-picturemaxx application and continue the login there.",
      "title": "Account is activated",
      "title_init": "Confirmation needed",
      "title_loading": "Activating account..."
    }
  }
</i18n>
<!-- eslint-enable max-len -->

<template>
  <div
    class="ipxx-verify-email ipxx-background-image"
  >
    <ipxx-mypm-header />
    <div class="ipxx-verify-email-content flex-grow-1 mx-4 pt-5">
      <div v-if="isLoading">
        <h1>{{ $t('title_loading') }}</h1>
        <fa-icon
          icon="circle-notch"
          size="6x"
          class="fa-spin"
        />
      </div>
      <div v-else-if="isSubmitted">
        <h1 v-if="isError">
          {{ $t('titles.error') }}
        </h1>
        <h1 v-else>
          {{ $t('title') }}
        </h1>
        <b-row class="justify-content-center">
          <div class="ipxx-form-responsive-col mt-3">
            <b-alert
              show
              :variant="messageType"
              :data-cy="messageType"
              v-html="messageText"
            />
          </div>
        </b-row>
      </div>
      <div v-else>
        <h1>{{ $t('title_init') }}</h1>
        <p>{{ $t('intro', {submitButtonText: submitButtonText}) }}</p>
        <b-row class="justify-content-center">
          <div class="ipxx-form-responsive-col">
            <b-button
              variant="primary"
              data-cy="emailVerifySubmitBtn"
              @click="submitVerifyEmail"
            >
              {{ $t('submit_button') }}
            </b-button>
          </div>
        </b-row>
      </div>
    </div>
    <ipxx-mypm-footer />
  </div>
</template>

<script>
import IpxxMypmFooter from '@/components/MypmFooter';
import IpxxMypmHeader from '@/components/MypmHeader';

export default {
  name: 'VerifyEmail',
  components: {
    IpxxMypmFooter,
    IpxxMypmHeader,
  },
  data() {
    return {
      errorCode: null,
      isSubmitted: false,
    };
  },
  computed: {
    alreadyVerified() {
      return this.errorCode === this.$config.errors.account_email_save_already_verified;
    },
    isError() {
      return this.errorCode && !this.alreadyVerified;
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
    messageText() {
      if (this.errorCode) {
        if (this.alreadyVerified) {
          return this.$t('success_already_verified');
        }
        if (this.errorCode === this.$config.errors.account_email_save_external_auth_disabled) {
          return this.$t('error_auth_disabled');
        }
        return this.$t('errors.unknown_error');
      }
      return this.$t('success_message');
    },
    messageType() {
      if (this.isError) {
        return 'danger';
      }
      return 'success';
    },
    submitButtonText() {
      return this.$t('submit_button');
    },
  },
  methods: {
    submitVerifyEmail() {
      const { token } = this.$route.query;
      this.$store.commit('setAuthToken', token);
      this.$store.commit('setLoading', true);

      this.$http.post(this.$routes.email_verify)
        .catch((error) => {
          if (error.data && error.data.failureId) {
            this.errorCode = error.data.failureId;
          } else {
            this.errorCode = this.$config.errors.internal_server_error;
          }
        })
        .finally(() => {
          this.$store.commit('setLoading', false);
          this.isSubmitted = true;
        });
    },
  },
};
</script>
